<!-- <app-experience [activity]="activity" *ngIf="user?.app_state?.showSetExperience" [class.showIn]="user?.app_state?.showSetExperience == 'in'" [class.showOut]="user?.app_state?.showSetExperience == 'out'"></app-experience> -->
<app-activity-days [activity]="activity" *ngIf="user?.app_state?.showSetDays" [class.showIn]="user?.app_state?.showSetDays == 'in'" [class.showOut]="user?.app_state?.showSetDays == 'out'"></app-activity-days>
<!-- <div class="items"> -->
<div class="items height100 vcenter animate__animated" *ngIf="user?.app_state?.showSetActivities" [class.animate__fadeInDown]="flags.animation=='start'" [class.animate__fadeOutUp]="flags.animation=='end'">
  <button mat-button class="closeBtn" [class.iosPadding]="utils.ios()" (click)="clickDone()" *ngIf="user?.color != null"><mat-icon>close</mat-icon></button>
  <!-- <div class="height90"> -->
    <div class="item">
      <div class="question" *ngIf="mode != 'interest'">
        <mat-icon>accessibility_new</mat-icon><br />
        Activities
      </div>
      <div class="question" *ngIf="mode == 'interest'">
        <mat-icon>spa</mat-icon><br />
        Interests
      </div>
      <br />  


      <form [formGroup]="form" (ngSubmit)="clickAddActivities()">
        <mat-form-field class="example-chip-list" *ngIf="activities.length < 8">
          <mat-chip-list #chipList aria-label="Activities">
            <!-- <mat-chip
              *ngFor="let activity of activities"
              [class.accentPill]="activity?.activity?.is_premium"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(activity)">
              {{activity?.activity?.name}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip> -->
            <input
              [placeholder]="placeholder"
              #activityInput
              [formControl]="activityCtrl"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="add($event)">
          </mat-chip-list>
          <!-- <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let activity of filteredActivities | async" [value]="activity">
              {{activity}}
            </mat-option>
          </mat-autocomplete> -->
          
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selected($event)">
            <mat-option *ngFor="let activity of filteredActivities | async" [value]="activity" [class.accentPill]="activity?.is_premium">
              {{activity?.name}}
            </mat-option>
          </mat-autocomplete>

        </mat-form-field>
      </form>

        <button mat-button mat-flat-button (click)="addFromButton(activityCtrl)" class="addBtn" [disabled]="disableBtn()" [class.btnReady2]="!disableBtn()" *ngIf="activities.length < 8"><mat-icon>keyboard_return</mat-icon></button>



        <div class="settings" *ngIf="activities.length > 0 && mode != 'interest'">
          <h1 *ngIf="utils.showPremium(user)">Your Rate on Request</h1>
          <div *ngFor="let activity of activities" class="entry">
            <div fxlayout="row" fxLayoutGap="20px" *ngIf="!activity?.is_interest">
              <div fxFlex="0 0 22" *ngIf="utils.showPremium(user)">
                <mat-form-field>
                  <span matPrefix>$ &nbsp;</span>
                  <!-- CAN"T GET SELECTED VALUE TO SELECT CURRENT VALUE "Free" -->
                    <mat-select placeholder="Price" (selectionChange)="priceChange($event.value, activity)"  [ngModel]="roundPrice(activity.price)">
                    <mat-option *ngFor="let price of prices" [value]="price">
                      {{price}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="0 0 24" *ngIf="utils.showPremium(user)">
                <mat-form-field>
                  <mat-select placeholder="Duration" (selectionChange)="durationChange($event.value, activity);" [value]="activity.duration">
                    <mat-option *ngFor="let duration of durations" [value]="duration.value">
                      {{duration.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex class="activity">
                <div class="xpill">
                  <button mat-button class="pill added" [class.accentPill]="activity?.activity?.is_premium" (click)="clickActivity(activity.activity,'activities')" [style.background]="'linear-gradient(180deg,'+user?.color+'d4,'+user?.color+'70)'">{{activity?.activity?.name}}</button>
                  <button mat-button class="pill delete" (click)="remove(activity)" [style.background]="'linear-gradient(180deg,'+user?.color+'d4,'+user?.color+'70)'"><mat-icon>close</mat-icon></button>
                </div>                  
              </div>
            </div>
          </div>  
        </div>
        <div *ngIf="mode == 'interest'">
          <div *ngFor="let activity of activities">
            <button mat-button class="pill added" [class.accentPill]="activity?.activity?.is_premium" [style.background]="'linear-gradient(180deg,'+user?.color+'d4,'+user?.color+'70)'" (click)="clickActivity(activity.activity,'activities')">{{activity?.activity?.name}}</button>
            <button mat-button class="pill delete" (click)="remove(activity)" [style.background]="'linear-gradient(180deg,'+user?.color+'d4,'+user?.color+'70)'"><mat-icon>close</mat-icon></button>
          </div>
        </div>





        <button [disabled]="flags?.btnAddingActivities" [class.btnReady2]="!flags?.btnAddingActivities" mat-flat-button color="primary" (click)="clickDone()" class="item height10">
          <mat-icon>arrow_forward</mat-icon>
        </button>
        
        <div class="text-center" *ngIf="activities.length >= 8">
          <h2>Excellent!</h2>
          <br />
          <p *ngIf="this.mode != 'interest'">You have 8 activities, you can also have 8 interests!</p>
          <p *ngIf="this.mode == 'interest'">You have 8 interests, you can also have 8 activities!</p>
        </div>

      <br clear="all" />
      <br />
      <ng-container *ngIf="popularActivities?.length > 0 && activities.length < 8">
        <div class="trending">
          <h2 class="text-center">Popular</h2>
          <div fxLayout="row" fxLayoutAlign="center center" class="hcenter">
            <button *ngFor="let activity of popularActivities|slice:0:flags.popularCount" mat-button type="button" class="pill" [class.accentPill]="activity?.is_premium" (click)="clickActivityPill(activity)">
              {{ activity?.name }} <span *ngIf="activity?.is_premium">*</span>
            </button>
          </div>
        </div>
      </ng-container>

    </div>



  <!-- </div> -->

</div>