import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  constructor(private http: HttpClient, private utils: UtilsService) {}

  async initPush() {
    if (!Capacitor.isNativePlatform()) {
      console.log('Push notifications are only available on native platforms.');
      return;
    }

    try {
      const permission = await PushNotifications.requestPermissions();
      if (permission.receive !== 'granted') {
        alert('Need notifications!\nFor when someone is trying to connect with you!');
        return;
      }

      await PushNotifications.register();
      const token = await this.waitForRegistration();
      if (token) {
        this.sendTokenToServer(token);
      }

      this.setupListeners();
    } catch (error) {
      console.error('Error initializing push notifications:', error);
    }
  }

  private waitForRegistration(): Promise<string> {
    return new Promise((resolve, reject) => {
      PushNotifications.addListener('registration', (token: Token) => {
        console.log('Push registration successful, token:', token.value);
        resolve(token.value);
      });

      PushNotifications.addListener('registrationError', (error: any) => {
        console.error('Push registration error:', error);
        reject(error);
      });

      setTimeout(() => reject(new Error('Push registration timeout')), 10000);
    });
  }

  private sendTokenToServer(token: string) {
    const data = {
      registration_id: token,
      cloud_message_type: 'FCM',
    };
    const url = this.utils.getUrl('rest:device-fcm-list');

    console.log('Registering token with server:', token, url);

    this.http.post(url, data).subscribe(
      (resp) => console.log('Device registered successfully:', resp),
      (err) => console.error('Failed to register device:', err)
    );
  }

  private setupListeners() {
    PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
      console.log('Push notification received:', JSON.stringify(notification));
    });

    PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
      console.log('Push action performed:', JSON.stringify(notification.notification));
    });
  }
}
