<mat-progress-bar mode="indeterminate" *ngIf="flags.loading" [class.iosProgress]="utils.ios()"></mat-progress-bar>
<ng-container *ngIf="posts">
  <div *ngIf="posts.length != 0">
    <div class="resultsHeader" [class.on]="searchStr != null"><mat-icon>date_range</mat-icon> Events</div>
  </div>  

  <div class="squaresContainer" (swipeleft)="swipeLeft()">
    <ng-container *ngFor="let post of posts; let i = index;">
    <a class="backgroundSquares fadeIn" *ngIf="utils.showPremium(user) || post?.price == 0"
      [style.background-image]="'url(' + displayImage(post) + ')'" 
      [@zoomBack]=user?.app_state?.homeBackground [routerLink]="'/' + post.creator?.username + '/' + post.id"
      [style.animation-delay]="'0.' +post?.meta?.timing + 's'" (click)="utils.sound('click-face')" [class.one]="nth(i,3)" [class.three]="nth(i+1,3)">
      <div fxlayout="row">
        <div class="price" *ngIf="utils.showPremium(user)">{{utils.displayPrice(post?.price)}}</div>

        <div class="title">
          <span *ngIf="!router.url.includes('/activity/')"><b>{{ post?.activity?.name }}</b> </span><br />
          <span>{{ utils.humanDate(post?.datetime) }} </span>
           <span *ngIf="utils.humanDate(post?.datetime) != 'Today' && utils.humanDate(post?.datetime) != 'Tomorrow'">{{ post?.datetime|date:'MM/dd' }}</span>
          <ng-container *ngIf="!post?.meta?.past">
            <br />@
            <span *ngIf="!utils.isNoon(post?.datetime)">{{ post?.datetime|date:'shortTime' }}</span>
            <span *ngIf="utils.isNoon(post?.datetime)">Noon</span>
          </ng-container>
        </div>
        <mat-icon class="premium" *ngIf="post?.activity?.is_premium">star</mat-icon>
        <!-- <button mat-button class="help" (click)="openExternal(post?.url)" *ngIf="post?.url != null"><mat-icon>help</mat-icon></button> -->
        <!-- <div class="pill">{{ post?.creator?.full_name }}</div> -->
        
      </div>
    </a>
    </ng-container>
  </div>

  <div class="noResults" *ngIf="searchStr == null && flags.noResults && !flags.loading">
    <h2><mat-icon>date_range</mat-icon></h2>
    <h5>No more posts found</h5>
    <br /><br />
    <button mat-button routerLink="/posts" onclick="window.scrollTo(0,0)" *ngIf="searchStr" (click)="back();searchStr=null;getPosts()"><mat-icon>arrow_back</mat-icon> Back</button>
    <br /><br />
  </div>  
</ng-container>
